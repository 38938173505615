import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { createHtml,createCss,createJs } from '../widget/boiler-plate'


const PreviewComponent = () => {
  // Get the HTML, JS, and widgetId from Redux state
  const widget = useSelector((state) => state.widget)

  const { widgetId } = useSelector((state) => state.widget.template)
  const html = createHtml(widget);  // Pass widget to createHtml
  const css = createCss(widget);    // Pass widget to createCss
  const js = createJs(widget);

  // Function to inject JS dynamically into the document
  const loadJs = () => {
    console.log('JS changed, loading script for widget:', widgetId)

    // Check for an existing script with the same widgetId and remove it
    const existingScript = document.getElementById(`script-${widgetId}`)
    if (existingScript) {
      console.log('Existing script found, removing it.')
      existingScript.remove()
    }

    // Create a new script element
    const script = document.createElement('script')
    script.id = `script-${widgetId}`  // Unique script ID based on widgetId
    script.type = 'text/javascript'
    script.innerHTML = js // Insert the JS code for the widget

    // Append the new script to the body
    document.body.appendChild(script)

    // Cleanup function to remove the script when the component is unmounted or widgetId/js changes
    return () => {
      console.log('Unmounting, removing script for widget:', widgetId)
      script.remove()
    }
  }

  // Run the script loading logic inside useEffect
  useEffect(() => {
    return loadJs() // On component mount and when `js` or `widgetId` changes
  }, [js, widgetId])  // Dependency array to re-run when JS or widgetId changes

  return (
    <div className="bg-gray-100 py-6">
      <div className="w-[60%] mx-auto">
        {/* Render the HTML content inside the component */}
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  )
}

export default PreviewComponent
