import './App.css'
import WidgetHeader from './components/widgetHeader'
import Preview from './components/preview-widget'
import { WidgetProvider } from './context/widget.context'
import Customize from './components/customize'
import { useSelector } from 'react-redux'
import Store from './config/Store'

function App() {
  const preview = useSelector((state) => state.widget.template.preview)

  return (
      <WidgetProvider>
        <WidgetHeader />
        <Preview />
      </WidgetProvider>
  )
}

export default App
