import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setWidget } from '../slices/WidgetSlice'

function WidgetHeader() {
  const dispatch = useDispatch()

  // Correctly access preview state from Redux store
  const { preview } = useSelector((state) => state.widget.template)

  const [selectedCalendar, setSelectedCalendar] = useState('Happy New Year')

  // Handle change of selected calendar
  const handleChange = (e) => {
    setSelectedCalendar(e.target.value)
  }

  const isDevMode = window.location.origin.includes('localhost')
  const handlePreviewToggle = () => {
    dispatch(setWidget({template:{preview:!preview}}))
  }

  return (
    <div className='w-[500px]'>
      {/* First Row: Icon, Title, Preview Toggle (if in Dev Mode) */}
      <div className='flex items-center gap-4 p-4'>
        <div className='flex items-center gap-4 p-4'>
          {/* Icon */}
          <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10'>
            <svg
              className='h-6 w-6 text-blue-600'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              aria-hidden='true'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M3 3h6v6H3V3zm12 0h6v6h-6V3zM3 15h6v6H3v-6zm12 0h6v3h-3v3h3m-6-3v3m-3-6h3v3h-3zm0 0v3m0-6h3'
              ></path>
            </svg>
          </div>

          {/* Title */}
          <h3 className='text-medium font-medium'>Qr Code Widget</h3>

          {/* Preview Toggle (Only in Dev Mode) */}
          {isDevMode && (
            <div
              onClick={handlePreviewToggle}
              className='text-blue-400 underline cursor-pointer ml-12'>
              {preview ? 'Close Preview' : 'Preview'}
            </div>
          )}
        </div>
      </div>

      {/* Divider (Optional) */}
      {/* <hr className='border-t border-gray-300 my-4' /> */}

      {/* Selected Calendar (Optional) */}
      <div className='px-5 mt-7 flex items-center justify-between'>
        <h4 className='text-medium text-gray-400 font-semibold'>
          Selected Calendar:{' '}
          <span className='text-black font-bold'>{selectedCalendar}</span>
        </h4>
        
        {/* Optional Dropdown for Calendar Selection */}
        <div className='flex-grow'>
          <select
            id='calendarSelect'
            className='w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-500'
            value={selectedCalendar}
            onChange={handleChange}
          >
            <option value='Happy New Year'>Happy New Year</option>
            <option value='Meeting Day'>Meeting Day</option>
            <option value='Tour Plan'>Tour Plan</option>
          </select>
        </div>
      </div>
    </div>
  )
}

export default WidgetHeader
