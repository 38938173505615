

export const createHtml = (widget) => {
  console.log('====================================');
  console.log("widget",widget);
  console.log('====================================');
  const iconHtml = widget?.template?.iconUrl
    ? `<div class="icon-container">
        <img src="${widget?.template?.iconUrl}" alt="Icon" class="qr-icon" />
       </div>`
    : '';

  return `
    <div class="qr-widget">
      <img src="https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
        widget?.template?.url
      )}&size=150x150&color=${widget?.template?.foregroundColor?.replace(
        '#',
        ''
      )}" alt="QR Code" class="qr-image" />
   <div class="icon-container">
        <img src="${widget?.template?.iconUrl}" alt="Icon" class="qr-icon" />
       </div>
      <p class="qr-text">${widget?.template?.dynamicText}</p>
    </div>
  `;
};


export const createCss = (widget) => {
  console.log('====================================');
  console.log("widget.template.frameColor",widget?.template?.frameColor);
  console.log('====================================');
  return `
    .qr-widget {
      padding: 10px;
      border: 5px solid ${widget?.template?.frameColor};
      background-color: ${widget?.template?.backgroundColor};
      border-radius: 10px;
      display: inline-block;
      text-align: center;
      position: relative;
    }

    .qr-image {
      width: 150px;
      height: 150px;
    }

    .qr-text {
      font-size: 16px;
      color: ${widget?.template?.textColor};
      margin-top: 10px;
    }

    .icon-container {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .qr-icon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  `;
};

export const createJs = (widget) => {
  return `
    document.querySelector('.qr-widget').addEventListener('click', function() {
      alert('QR Code clicked!');
    });
  `;
};


export const createHtmlPage = (css, body) =>
  `<style>${css}</style><div>${body}</div>`;
