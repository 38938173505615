import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import Postmate from 'postmate';
import { cloneDeep } from 'lodash';
import { setWidget } from '../slices/WidgetSlice'; // Import actions from the slice
import { createHtml, createCss, createJs } from '../widget/boiler-plate';

// Initialize Postmate handshake
const handshake = new Postmate.Model({});

// Function to create the full HTML page with CSS and body
const createHtmlPage = (css, body) => `<style>${css}</style><div>${body}</div>`;

// WidgetProvider component to manage widget state and updates
export const WidgetProvider = ({ children }) => {
  const dispatch = useDispatch();

  // Retrieve widget state from Redux
  const { widget, html, css, js, preview } = useSelector((state) => state.widget);

  // Emit code to the parent (Postmate handshake)
  const emitCode = ({ html, js }) => {
    console.log('Sending Updates');
    handshake?.then(async (parent) => {
      if (parent) {
        const code = {
          html,
          js,
          elementStore: cloneDeep(widget), // Deep clone to prevent unintended mutation
        };
        console.log('Emitting Code', code);
        parent?.emit('code', code);
      }
    });
  };

  // Update HTML, CSS, JS whenever the widget is modified
  useEffect(() => {
    const newCss = createCss(widget);
    const newJs = createJs(widget);
    const newBody = createHtml(widget);
    const newHtml = createHtmlPage(newCss, newBody);

    // Dispatch actions to update the HTML, CSS, and JS in the store
    dispatch(setWidget({
      template: { html: newHtml, css: newCss, js: newJs },
    }));

    // Emit the new code to the parent
    emitCode({ html: newHtml, js: newJs });
  }, []); // Runs when `widget` changes

  // Handle handshake and retrieve model on load
  const onLoad = () => {
    handshake?.then(async (parent) => {
      if (parent?.model?.elementStore) {
        console.log('Model from parent:', parent.model?.elementStore);
        if (JSON.stringify(widget) !== JSON.stringify(parent.model?.elementStore)) {
          dispatch(setWidget(parent.model?.elementStore));
        }
      } else {
        // Emit the current widget configuration if no model is found
        emitCode({ html, js });
      }
    });
  };

  // Trigger `onLoad` only once when the component mounts
  useEffect(() => {
    onLoad();
  }, []); // Empty array ensures this runs only once on mount

  return (
    <div>
      <div>{children}</div>
    </div>
  );
};
