import { configureStore } from '@reduxjs/toolkit';
import widgetReducer from '../slices/WidgetSlice';

const Store = configureStore({
  reducer: {
    widget: widgetReducer,
  },
});

export default Store;
