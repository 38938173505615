import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { createHtml, createCss, createJs } from '../widget/boiler-plate';

const initialState = {
  template: {
    selectedTemplate: 'Basic',
    visibleCount: 6,
    rangeValue: 1220,
    isOpen: false,
    mobileView: false,
    layoutCustomize: false,
    selectedFeature: '',
    screenName: '',
    homeShow: true,
    selectedColor: 'Slider',
    placeDetails: null,
    loading: false,
    error: null,
    searchValue: '',
    html: '',
    css: '',
    js: '',
    url: 'https://example.com',
    foregroundColor: '#000',
    backgroundColor: '#ffffff',
    frameColor: '#000000',
    textColor: '#000000', // Adding textColor
    dynamicText: 'Scan Me', // Adding dynamic text
    iconUrl: '', // Placeholder for icon image
    widgetId: uuid(), // Unique widget ID
  },
  form: {
    numberWhatsapp: 923147600000,
    preFilledMsg: '',
    caption: 'Hi there 👋 How can I help you?',
    name: 'Muhammad Awais',
    bubbleText: '',
    btnText: 'WhatsApp',
    customCaption: '',
    replyText: 'Typically replies instantly',
    file: null,
    uploadType: null,
    profileUrl: 'https://static.elfsight.com/apps/all-in-one-chat/avatars/general.jpeg',
    placeHolder: 'Enter Your Message',
    header: false,
    currentItemId: 'HeadSlider0',
    currentItemIdReview: 'slider1',
    currentItemIdReviewSource: 'sliderSource1',
    mainHeader: false,
  },
  reviewSettings: {
    startChatButton: false,
    sendMsg: false,
    showIconInBtn: false,
    showReviewSource: false,
    showDate: false,
  },
  style: {
    mode: 'Online',
    accentColor: '#197BFF',
    bgColor: 'transparent',
    font: 'Arial',
    paddingHorizontalValue: 0,
    paddingVerticalValue: 0,
    paddingHorizontalButton: 20,
    paddingVerticalValueButton: 15,
    bubble_background_color: '#000000',
    bubble_icon_color: '#FFFFFF',
    notification_badge_color: '#4AD504',
    header_bg_color: '#008069',
    chat_wallpaper: null,
    msg_bg_color: '#FFFFFF',
    msg_field_color: '#FFFFFF',
    send_msg_color: '#000000',
    source_icon_background_color: '#FFFFFF',
    border_radius: 24,
    loadmore_button: '#1494FF',
    outline_button_color: '#ffff',
    header_bgColor: '#F8F8F8',
    header_textColor: '#000000',
    source_logoColor: '#000000',
    border_radius_header: 6,
    border_radius_LoadMore: 6,
    isOpen: false,
  },
  settings: {
    isExternalLinksChecked: false,
    isOpenLinksInNewTabChecked: false,
  },
  tabsData: [],
};

createHtml(initialState)
createCss(initialState)

const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    // This is the reducer where we set the widget state
    setWidget: (state, action) => {
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload.form, // Spread the form object to merge changes
        },
        reviewSettings: {
          ...state.reviewSettings,
          ...action.payload.reviewSettings, // Merge reviewSettings if they exist in payload
          selectedOptions: {
            ...state.reviewSettings.selectedOptions,
            ...action.payload.reviewSettings?.selectedOptions, // Merge selectedOptions if they exist
          },
        },
        template: {
          ...state.template,
          ...action.payload.template, // Merge template properties
        },
        style: {
          ...state.style,
          ...action.payload.style, // Merge style properties
        },
        tabsData: action.payload.tabsData ?? state.tabsData, // If tabsData exists in payload, use it; otherwise, keep existing
      };
    },
    
    // Other actions can be defined here for specific changes
    openModal: (state) => {
      state.style.isOpen = true;
    },
    closeModal: (state) => {
      state.style.isOpen = false;
    },
    toggleLayoutCustomize: (state) => {
      state.template.layoutCustomize = !state.template.layoutCustomize;
    },
    setPaddingHorizontal: (state, action) => {
      state.style.paddingHorizontalValue = action.payload;
    },
    setPaddingVertical: (state, action) => {
      state.style.paddingVerticalValue = action.payload;
    },
  },
});

export const { setWidget, openModal, closeModal, toggleLayoutCustomize, setPaddingHorizontal, setPaddingVertical } = widgetSlice.actions;
export default widgetSlice.reducer;
